import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Sobre Nós</h1>

      <div className="space-y-8 text-gray-700">
        {/* Hero Section */}
        <div className="relative h-64 mb-12 rounded-lg overflow-hidden">
          <img
            src="/about-hero.jpg"
            alt="Relógio Digital - Gerenciamento de Tempo"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
            <h2 className="text-white text-2xl font-bold text-center">
              Transformando o Gerenciamento de Tempo
            </h2>
          </div>
        </div>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Nossa Missão</h2>
          <p className="mb-4">
            No Relógio Digital, nossa missão é fornecer ferramentas precisas e intuitivas para
            ajudar você a gerenciar seu tempo de forma eficiente. Acreditamos que o tempo é um
            recurso valioso e queremos torná-lo mais produtivo e organizado.
          </p>
          <p>
            Com mais de 10.000 usuários ativos, nos dedicamos a criar soluções que tornam o
            gerenciamento de tempo mais simples e eficaz para todos, desde estudantes até
            profissionais ocupados.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Nossa História</h2>
          <p className="mb-4">
            Fundado em 2024, o Relógio Digital nasceu da necessidade de criar uma solução simples e
            confiável para gerenciamento de tempo. Nossa equipe de desenvolvedores apaixonados
            trabalha constantemente para melhorar e expandir nossas funcionalidades.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            <div className="bg-white p-4 rounded-lg shadow-sm text-center">
              <div className="text-3xl font-bold text-green-500 mb-2">10K+</div>
              <div className="text-gray-600">Usuários Ativos</div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm text-center">
              <div className="text-3xl font-bold text-green-500 mb-2">4</div>
              <div className="text-gray-600">Ferramentas Principais</div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm text-center">
              <div className="text-3xl font-bold text-green-500 mb-2">24/7</div>
              <div className="text-gray-600">Suporte Disponível</div>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Nossos Valores</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <h3 className="font-semibold text-lg mb-2">Precisão</h3>
              <p>Comprometimento com a exatidão e confiabilidade em todas as nossas ferramentas.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="font-semibold text-lg mb-2">Simplicidade</h3>
              <p>Interface intuitiva e fácil de usar, sem comprometer a funcionalidade.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                  />
                </svg>
              </div>
              <h3 className="font-semibold text-lg mb-2">Inovação</h3>
              <p>
                Busca constante por melhorias e novas funcionalidades para atender às necessidades
                dos usuários.
              </p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Nossas Ferramentas</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  className="w-8 h-8 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <h3 className="font-semibold text-lg mb-2 text-center">Relógio Digital</h3>
              <p className="mb-4 text-center">
                Exibição precisa do horário atual com suporte a diferentes fusos horários.
              </p>
              <div className="text-center">
                <Link to="/" className="text-green-500 hover:text-green-600 font-medium">
                  Experimente agora →
                </Link>
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  className="w-8 h-8 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="font-semibold text-lg mb-2 text-center">Cronômetro</h3>
              <p className="mb-4 text-center">
                Ferramenta para medir intervalos de tempo com precisão e facilidade.
              </p>
              <div className="text-center">
                <Link to="/" className="text-green-500 hover:text-green-600 font-medium">
                  Experimente agora →
                </Link>
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  className="w-8 h-8 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
              </div>
              <h3 className="font-semibold text-lg mb-2 text-center">Timer</h3>
              <p className="mb-4 text-center">
                Configuração de contagem regressiva para suas atividades.
              </p>
              <div className="text-center">
                <Link to="/" className="text-green-500 hover:text-green-600 font-medium">
                  Experimente agora →
                </Link>
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  className="w-8 h-8 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </div>
              <h3 className="font-semibold text-lg mb-2 text-center">Alarme</h3>
              <p className="mb-4 text-center">
                Sistema de alertas para não perder seus compromissos importantes.
              </p>
              <div className="text-center">
                <Link to="/" className="text-green-500 hover:text-green-600 font-medium">
                  Experimente agora →
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Nossa Equipe</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <img
                src="/member1.jpg"
                alt="João Silva"
                className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
              />
              <h3 className="font-semibold text-lg">João Silva</h3>
              <p className="text-gray-600">Desenvolvedor Principal</p>
            </div>
            <div className="text-center">
              <img
                src="/member2.jpg"
                alt="Maria Santos"
                className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
              />
              <h3 className="font-semibold text-lg">Maria Santos</h3>
              <p className="text-gray-600">Designer UI/UX</p>
            </div>
            <div className="text-center">
              <img
                src="/member3.jpg"
                alt="Pedro Oliveira"
                className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
              />
              <h3 className="font-semibold text-lg">Pedro Oliveira</h3>
              <p className="text-gray-600">Desenvolvedor Frontend</p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Contato</h2>
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <p className="mb-4">
              Estamos sempre abertos a sugestões e feedback. Entre em contato conosco através dos
              seguintes canais:
            </p>
            <ul className="space-y-2">
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <a
                  href="mailto:contato@relogiodigital.com"
                  className="text-green-500 hover:text-green-600"
                >
                  contato@relogiodigital.com
                </a>
              </li>
              
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
