import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

function Layout() {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isActive = path => {
    return location.pathname === path;
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <header className="bg-white shadow-lg relative">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-8">
              <Link to="/" className="flex items-center space-x-2">
                <div className="w-10 h-10 bg-green-500 rounded-lg flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-green-500 to-green-600 bg-clip-text text-transparent">
                  Relógio Digital
                </span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-6">
              <Link
                to="/"
                className={`text-sm font-medium ${
                  isActive('/') ? 'text-green-600' : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                Início
              </Link>
              <Link
                to="/blog"
                className={`text-sm font-medium ${
                  isActive('/blog') ? 'text-green-600' : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                Blog
              </Link>
              <Link
                to="/sobre"
                className={`text-sm font-medium ${
                  isActive('/sobre') ? 'text-green-600' : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                Sobre
              </Link>
            </nav>

            <div className="flex items-center space-x-4">
              <div className="hidden sm:block text-sm text-gray-500 bg-gray-50 px-3 py-1 rounded-lg">
                {new Date().toLocaleDateString('pt-BR', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </div>
              <button
                onClick={toggleMobileMenu}
                className="md:hidden p-2 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                <svg
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Mobile Navigation */}
          <div
            className={`${
              isMobileMenuOpen ? 'block' : 'hidden'
            } md:hidden absolute top-full left-0 right-0 bg-white shadow-lg z-50 border-t border-gray-100`}
          >
            <nav className="px-4 py-2 space-y-2">
              <Link
                to="/"
                className={`block px-4 py-2 text-base font-medium rounded-lg ${
                  isActive('/') ? 'text-green-600 bg-green-50' : 'text-gray-500 hover:bg-gray-50'
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Início
              </Link>
              <Link
                to="/blog"
                className={`block px-4 py-2 text-base font-medium rounded-lg ${
                  isActive('/blog')
                    ? 'text-green-600 bg-green-50'
                    : 'text-gray-500 hover:bg-gray-50'
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Blog
              </Link>
              <Link
                to="/sobre"
                className={`block px-4 py-2 text-base font-medium rounded-lg ${
                  isActive('/sobre')
                    ? 'text-green-600 bg-green-50'
                    : 'text-gray-500 hover:bg-gray-50'
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Sobre
              </Link>
            </nav>
          </div>
        </div>
      </header>

      <Outlet />

      <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-white mt-12">
        <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Coluna 1 - Logo e Descrição */}
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <div className="w-10 h-10 bg-green-500 rounded-lg flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span className="text-xl font-bold">Relógio Digital</span>
              </div>
              <p className="text-gray-400 text-sm">
                Precisão e simplicidade para suas necessidades de tempo. Ferramentas úteis para
                gerenciar seu tempo de forma eficiente.
              </p>
            </div>

            {/* Coluna 2 - Links Rápidos */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Links Rápidos</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/" className="text-gray-400 hover:text-green-500 transition-colors">
                    Início
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="text-gray-400 hover:text-green-500 transition-colors">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sobre"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Sobre Nós
                  </Link>
                </li>
              </ul>
            </div>

            {/* Coluna 3 - Legal */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/privacidade"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Política de Privacidade
                  </Link>
                </li>
                <li>
                  <Link
                    to="/termos"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Termos de Uso
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cookies"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Cookies
                  </Link>
                </li>
              </ul>
            </div>

            {/* Coluna 4 - Contato */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Contato</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/faq" className="text-gray-400 hover:text-green-500 transition-colors">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Linha Divisória */}
          <div className="border-t border-gray-700 mt-12 pt-8">
            <div className="flex justify-center">
              <p className="text-gray-400 text-sm">
                © {new Date().getFullYear()} Relógio Digital. Todos os direitos reservados.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
