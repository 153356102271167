import React from 'react';

function ClockDisplay({ time }) {
  const formatTime = date => {
    return date.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  };

  const formatDate = date => {
    return date.toLocaleDateString('pt-BR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <div className="absolute inset-0 bg-green-500 rounded-full blur-xl opacity-20"></div>
        <div className="relative font-mono text-4xl sm:text-6xl md:text-8xl font-bold text-green-600 tracking-wider">
          {formatTime(time)}
        </div>
      </div>
      <div className="text-base sm:text-xl md:text-2xl text-gray-600 mt-4 capitalize font-medium">
        {formatDate(time)}
      </div>
      <div className="mt-4 sm:mt-6 flex items-center space-x-4">
        <div className="flex items-center">
          <div className="w-2 h-2 sm:w-3 sm:h-3 bg-green-500 rounded-full mr-2"></div>
          <span className="text-xs sm:text-sm text-gray-500">Online</span>
        </div>
        <div className="flex items-center">
          <div className="w-2 h-2 sm:w-3 sm:h-3 bg-green-500 rounded-full mr-2"></div>
          <span className="text-xs sm:text-sm text-gray-500">Sincronizado</span>
        </div>
      </div>
    </div>
  );
}

export default ClockDisplay;
