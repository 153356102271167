import React from 'react';

function TermsOfUse() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Termos de Uso</h1>

      <div className="space-y-6 text-gray-700">
        <section>
          <h2 className="text-xl font-semibold mb-4">1. Aceitação dos Termos</h2>
          <p>
            Ao acessar e usar o Relógio Digital, você concorda em cumprir estes termos de uso. Se
            você não concordar com qualquer parte destes termos, não poderá usar nosso serviço.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">2. Uso do Serviço</h2>
          <p>
            O Relógio Digital é fornecido &ldquo;como está&rdquo; e &ldquo;conforme
            disponível&rdquo;. Não garantimos que o serviço será ininterrupto, oportuno, seguro ou
            livre de erros.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">3. Propriedade Intelectual</h2>
          <p>
            Todo o conteúdo, funcionalidades e tecnologia do Relógio Digital são de propriedade
            exclusiva do Relógio Digital e estão protegidos por leis de direitos autorais, marcas
            registradas e outras leis de propriedade intelectual.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">4. Limitações de Responsabilidade</h2>
          <p>
            O Relógio Digital não será responsável por quaisquer danos indiretos, incidentais,
            especiais, consequenciais ou punitivos resultantes do uso ou incapacidade de usar o
            serviço.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">5. Modificações do Serviço</h2>
          <p>
            Reservamos o direito de modificar ou descontinuar o serviço a qualquer momento, com ou
            sem aviso prévio. Não seremos responsáveis perante você ou terceiros por qualquer
            modificação, suspensão ou descontinuação do serviço.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">6. Lei Aplicável</h2>
          <p>
            Estes termos serão regidos e interpretados de acordo com as leis do Brasil,
            independentemente de conflitos de disposições legais.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">7. Contato</h2>
          <p>
            Para questões sobre estes termos de uso, entre em contato conosco através do email:
            termos@relogiodigital.com
          </p>
        </section>
      </div>
    </div>
  );
}

export default TermsOfUse;
