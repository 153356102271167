import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Política de Privacidade</h1>

      <div className="space-y-6 text-gray-700">
        <section>
          <h2 className="text-xl font-semibold mb-4">1. Coleta de Informações</h2>
          <p>
            O Relógio Digital coleta informações mínimas necessárias para o funcionamento do
            serviço. Não armazenamos dados pessoais dos usuários, exceto quando explicitamente
            solicitado para funcionalidades específicas.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">2. Uso de Cookies</h2>
          <p>
            Utilizamos cookies essenciais para manter suas preferências e melhorar sua experiência.
            Estes cookies são necessários para o funcionamento básico do site e não podem ser
            desativados.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">3. Compartilhamento de Dados</h2>
          <p>
            Não compartilhamos suas informações pessoais com terceiros, exceto quando exigido por
            lei ou com seu consentimento explícito.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">4. Segurança</h2>
          <p>
            Implementamos medidas de segurança para proteger suas informações. No entanto, nenhum
            método de transmissão pela internet é 100% seguro.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">5. Seus Direitos</h2>
          <p>
            Você tem direito a acessar, corrigir ou solicitar a exclusão de seus dados pessoais.
            Entre em contato conosco para exercer esses direitos.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">6. Alterações na Política</h2>
          <p>
            Podemos atualizar esta política periodicamente. Recomendamos que você revise esta página
            regularmente para se manter informado sobre como protegemos suas informações.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">7. Contato</h2>
          <p>
            Para questões sobre esta política de privacidade, entre em contato conosco através do
            email: privacidade@relogiodigital.com
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
