import React from 'react';

function Timer({ time, isRunning, onStart, onPause, onStop, onTimeChange }) {
  const formatTime = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleTimeChange = (unit, value) => {
    const newTime = { ...time };
    switch (unit) {
      case 'hours':
        newTime.hours = Math.max(0, Math.min(23, parseInt(value) || 0));
        break;
      case 'minutes':
        newTime.minutes = Math.max(0, Math.min(59, parseInt(value) || 0));
        break;
      case 'seconds':
        newTime.seconds = Math.max(0, Math.min(59, parseInt(value) || 0));
        break;
      default:
        return;
    }
    onTimeChange(newTime);
  };

  const totalSeconds = time.hours * 3600 + time.minutes * 60 + time.seconds;

  return (
    <div className="flex flex-col items-center">
      <div className="font-mono text-5xl font-bold text-green-600 tracking-wider mb-6">
        {formatTime(totalSeconds)}
      </div>

      <div className="flex gap-4 mb-6">
        <div className="flex flex-col items-center">
          <input
            type="number"
            min="0"
            max="23"
            value={time.hours}
            onChange={e => handleTimeChange('hours', e.target.value)}
            className="w-16 text-center text-xl border-2 border-gray-300 rounded-lg focus:border-green-500 focus:outline-none"
            disabled={isRunning}
          />
          <span className="text-sm text-gray-600 mt-1">Horas</span>
        </div>
        <div className="flex flex-col items-center">
          <input
            type="number"
            min="0"
            max="59"
            value={time.minutes}
            onChange={e => handleTimeChange('minutes', e.target.value)}
            className="w-16 text-center text-xl border-2 border-gray-300 rounded-lg focus:border-green-500 focus:outline-none"
            disabled={isRunning}
          />
          <span className="text-sm text-gray-600 mt-1">Minutos</span>
        </div>
        <div className="flex flex-col items-center">
          <input
            type="number"
            min="0"
            max="59"
            value={time.seconds}
            onChange={e => handleTimeChange('seconds', e.target.value)}
            className="w-16 text-center text-xl border-2 border-gray-300 rounded-lg focus:border-green-500 focus:outline-none"
            disabled={isRunning}
          />
          <span className="text-sm text-gray-600 mt-1">Segundos</span>
        </div>
      </div>

      <div className="flex gap-4">
        <button
          onClick={isRunning ? onPause : onStart}
          className={`px-6 py-2 rounded-lg font-semibold transition-colors ${
            isRunning
              ? 'bg-yellow-500 hover:bg-yellow-600 text-white'
              : 'bg-green-500 hover:bg-green-600 text-white'
          }`}
        >
          {isRunning ? 'Pausar' : 'Iniciar'}
        </button>
        <button
          onClick={onStop}
          className="px-6 py-2 rounded-lg font-semibold bg-red-500 hover:bg-red-600 text-white transition-colors"
        >
          Parar
        </button>
      </div>
    </div>
  );
}

export default Timer;
