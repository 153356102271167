import React from 'react';

function Cookies() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Política de Cookies</h1>

      <div className="space-y-6 text-gray-700">
        <section>
          <h2 className="text-xl font-semibold mb-4">1. O que são Cookies?</h2>
          <p>
            Cookies são pequenos arquivos de texto que são armazenados no seu dispositivo quando
            você visita nosso site. Eles nos ajudam a melhorar sua experiência de navegação e a
            entender como você usa nosso serviço.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">2. Tipos de Cookies que Utilizamos</h2>
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold">Cookies Essenciais</h3>
              <p>
                Necessários para o funcionamento básico do site. Eles permitem funcionalidades como
                navegação entre páginas e acesso a áreas seguras.
              </p>
            </div>
            <div>
              <h3 className="font-semibold">Cookies de Preferências</h3>
              <p>
                Permitem que o site lembre suas escolhas e preferências, como seu fuso horário
                preferido ou configurações de idioma.
              </p>
            </div>
            <div>
              <h3 className="font-semibold">Cookies de Desempenho</h3>
              <p>
                Nos ajudam a entender como os visitantes interagem com o site, coletando e
                reportando informações anonimamente.
              </p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">3. Como Gerenciar Cookies</h2>
          <p>
            Você pode controlar e/ou excluir cookies conforme desejar. Você pode excluir todos os
            cookies que já estão no seu computador e configurar a maioria dos navegadores para
            impedir que eles sejam colocados. No entanto, se você fizer isso, pode ter que ajustar
            manualmente algumas preferências toda vez que visitar um site.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">4. Atualizações da Política</h2>
          <p>
            Podemos atualizar nossa política de cookies periodicamente. Recomendamos que você
            verifique esta página regularmente para se manter informado sobre como usamos cookies.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">5. Contato</h2>
          <p>
            Se você tiver dúvidas sobre nossa política de cookies, entre em contato conosco através
            do email: cookies@relogiodigital.com
          </p>
        </section>
      </div>
    </div>
  );
}

export default Cookies;
