import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import ImagePlaceholder from '../components/ImagePlaceholder';

const GenerateImages = () => {
  const [error, setError] = useState(null);
  const [generating, setGenerating] = useState(false);
  const containerRef = useRef(null);

  const images = [
    {
      title: 'Método Pomodoro',
      filename: 'pomodoro-technique.jpg',
      bgColor: 'bg-red-500',
      type: 'blog',
    },
    {
      title: 'Rotina Matinal',
      filename: 'morning-routine.jpg',
      bgColor: 'bg-yellow-500',
      type: 'blog',
    },
    {
      title: 'Técnicas de Cronometragem',
      filename: 'stopwatch-techniques.jpg',
      bgColor: 'bg-blue-500',
      type: 'blog',
    },
    {
      title: 'Home Office',
      filename: 'home-office.jpg',
      bgColor: 'bg-purple-500',
      type: 'blog',
    },
    {
      title: 'Mindfulness',
      filename: 'mindfulness.jpg',
      bgColor: 'bg-teal-500',
      type: 'blog',
    },
    {
      title: 'Atualizações',
      filename: 'updates.jpg',
      bgColor: 'bg-green-600',
      type: 'blog',
    },
    // Imagens dos autores
    {
      title: 'MS',
      filename: 'maria-santos.jpg',
      bgColor: 'bg-pink-500',
      type: 'authors',
      width: 200,
      height: 200,
    },
    {
      title: 'JS',
      filename: 'joao-silva.jpg',
      bgColor: 'bg-blue-600',
      type: 'authors',
      width: 200,
      height: 200,
    },
    {
      title: 'PO',
      filename: 'pedro-oliveira.jpg',
      bgColor: 'bg-green-600',
      type: 'authors',
      width: 200,
      height: 200,
    },
    {
      title: 'AL',
      filename: 'ana-luiza.jpg',
      bgColor: 'bg-purple-600',
      type: 'authors',
      width: 200,
      height: 200,
    },
    {
      title: 'CE',
      filename: 'carlos-eduardo.jpg',
      bgColor: 'bg-yellow-600',
      type: 'authors',
      width: 200,
      height: 200,
    },
    {
      title: 'ET',
      filename: 'equipe-tecnica.jpg',
      bgColor: 'bg-gray-600',
      type: 'authors',
      width: 200,
      height: 200,
    },
  ];

  const downloadImage = async (element, filename) => {
    try {
      const canvas = await html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
        logging: true,
        onclone: document => {
          // Ensure all fonts are loaded
          document.fonts.ready.then(() => {
            console.log('Fonts loaded');
          });
        },
      });

      const link = document.createElement('a');
      link.download = filename;
      link.href = canvas.toDataURL('image/jpeg', 0.8);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    } catch (err) {
      console.error('Error generating image:', err);
      setError(`Error generating ${filename}: ${err.message}`);
      return false;
    }
  };

  const generateAllImages = async () => {
    setGenerating(true);
    setError(null);

    const elements = containerRef.current.querySelectorAll('.image-container');
    let successCount = 0;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const image = images[i];
      const success = await downloadImage(element, image.filename);
      if (success) successCount++;
    }

    setGenerating(false);
    if (successCount === elements.length) {
      setError('Todas as imagens foram geradas com sucesso!');
    } else {
      setError(`${successCount} de ${elements.length} imagens foram geradas.`);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Gerador de Imagens</h1>

      <div className="mb-8">
        <button
          onClick={generateAllImages}
          disabled={generating}
          className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg disabled:opacity-50"
        >
          {generating ? 'Gerando...' : 'Gerar Todas as Imagens'}
        </button>

        {error && (
          <div
            className={`mt-4 p-4 rounded-lg ${error.includes('sucesso') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}
          >
            {error}
          </div>
        )}
      </div>

      <div className="space-y-8" ref={containerRef}>
        <section>
          <h2 className="text-2xl font-semibold mb-4">Imagens do Blog</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {images
              .filter(img => img.type === 'blog')
              .map((image, index) => (
                <div key={index} className="space-y-4">
                  <div className="image-container">
                    <ImagePlaceholder
                      title={image.title}
                      bgColor={image.bgColor}
                      width={image.width}
                      height={image.height}
                    />
                  </div>
                  <p className="text-center text-gray-600">{image.filename}</p>
                </div>
              ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Fotos dos Autores</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
            {images
              .filter(img => img.type === 'authors')
              .map((image, index) => (
                <div key={index} className="space-y-4">
                  <div className="image-container">
                    <ImagePlaceholder
                      title={image.title}
                      bgColor={image.bgColor}
                      width={image.width}
                      height={image.height}
                    />
                  </div>
                  <p className="text-center text-gray-600">{image.filename}</p>
                </div>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default GenerateImages;
