import React from 'react';

const ImagePlaceholder = ({ title, width = 800, height = 400, bgColor = 'bg-green-500' }) => {
  return (
    <div
      className={`${bgColor} flex items-center justify-center text-white p-4 text-center`}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <h2 className="text-2xl font-bold">{title}</h2>
    </div>
  );
};

export default ImagePlaceholder;
