import React, { useState, useEffect, useRef } from 'react';
import ClockDisplay from '../components/ClockDisplay';
import Stopwatch from '../components/Stopwatch';
import Timer from '../components/Timer';
import Alarm from '../components/Alarm';

// Som de alarme em base64 (beep simples)
const alarmSound =
  'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YQQAAAB/f39/f39/fw==';

function Home() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [cronometro, setCronometro] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [savedTimes, setSavedTimes] = useState([]);
  const [timerTime, setTimerTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [timerRunning, setTimerRunning] = useState(false);
  const [timerPaused, setTimerPaused] = useState(false);
  const [alarms, setAlarms] = useState([]);
  const [sound] = useState(new Audio(alarmSound));
  const mainContentRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (isRunning) {
      const startTime = Date.now() - cronometro * 1000;
      const timer = setInterval(() => {
        setCronometro((Date.now() - startTime) / 1000);
      }, 10);

      return () => clearInterval(timer);
    }
  }, [isRunning, cronometro]);

  useEffect(() => {
    if (timerRunning && !timerPaused) {
      const timer = setInterval(() => {
        setTimerTime(prev => {
          if (prev.hours === 0 && prev.minutes === 0 && prev.seconds === 0) {
            setTimerRunning(false);
            sound.play();
            return prev;
          }
          if (prev.seconds === 0) {
            if (prev.minutes === 0) {
              return { hours: prev.hours - 1, minutes: 59, seconds: 59 };
            }
            return { ...prev, minutes: prev.minutes - 1, seconds: 59 };
          }
          return { ...prev, seconds: prev.seconds - 1 };
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timerRunning, timerPaused, sound]);

  useEffect(() => {
    const checkAlarms = () => {
      const now = new Date();
      alarms.forEach(alarm => {
        if (
          alarm.enabled &&
          alarm.time.getHours() === now.getHours() &&
          alarm.time.getMinutes() === now.getMinutes() &&
          alarm.days.includes(now.getDay())
        ) {
          sound.play();
        }
      });
    };

    const timer = setInterval(checkAlarms, 1000);
    return () => clearInterval(timer);
  }, [alarms, sound]);

  const handleStartCronometro = () => setIsRunning(true);
  const handleStopCronometro = () => setIsRunning(false);
  const handleResetCronometro = () => {
    setIsRunning(false);
    setCronometro(0);
  };
  const handleSaveCronometro = () => {
    setSavedTimes(prev => [...prev, cronometro]);
  };
  const handleLoadTime = time => {
    setCronometro(time);
    setIsRunning(false);
  };
  const handleClearSavedTimes = () => setSavedTimes([]);

  const handleStartTimer = () => {
    if (timerTime.hours > 0 || timerTime.minutes > 0 || timerTime.seconds > 0) {
      setTimerRunning(true);
      setTimerPaused(false);
    }
  };
  const handlePauseTimer = () => setTimerPaused(true);
  const handleStopTimer = () => {
    setTimerRunning(false);
    setTimerPaused(false);
    setTimerTime({ hours: 0, minutes: 0, seconds: 0 });
    sound.pause();
    sound.currentTime = 0;
  };
  const handleTimerTimeChange = newTime => setTimerTime(newTime);

  const handleAddAlarm = alarm => {
    setAlarms(prev => [...prev, alarm]);
  };
  const handleRemoveAlarm = id => {
    setAlarms(prev => prev.filter(alarm => alarm.id !== id));
  };
  const handleToggleAlarm = id => {
    setAlarms(prev =>
      prev.map(alarm => (alarm.id === id ? { ...alarm, enabled: !alarm.enabled } : alarm))
    );
  };

  return (
    <>
      <main className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 py-4 sm:py-8">
        {/* Hero Section */}
        <div className="text-center mb-6 sm:mb-12">
          <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 mb-2 sm:mb-4">
            Relógio Digital Online
          </h2>
          <p className="text-base sm:text-xl text-gray-600">
            Precisão e simplicidade para suas necessidades de tempo
          </p>
        </div>

        {/* Main Clock Section */}
        <div className="bg-white rounded-xl sm:rounded-2xl shadow-lg sm:shadow-xl p-4 sm:p-8 mb-6 sm:mb-12">
          <ClockDisplay time={currentTime} />
        </div>

        {/* Tools Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 mb-8 sm:mb-12">
          {/* Stopwatch Section */}
          <div className="bg-white rounded-lg sm:rounded-2xl shadow p-4 sm:p-6 lg:p-8 transform hover:scale-105 transition-transform duration-200">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 flex items-center">
              <svg
                className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-green-500 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="flex-1">Cronômetro</span>
            </h2>
            <div className="overflow-x-auto">
              <Stopwatch
                cronometro={cronometro}
                isRunning={isRunning}
                onStart={handleStartCronometro}
                onStop={handleStopCronometro}
                onReset={handleResetCronometro}
                onSave={handleSaveCronometro}
                savedTimes={savedTimes}
                onLoadTime={handleLoadTime}
                onClearSavedTimes={handleClearSavedTimes}
              />
            </div>
          </div>

          {/* Timer Section */}
          <div className="bg-white rounded-lg sm:rounded-2xl shadow p-4 sm:p-6 lg:p-8 transform hover:scale-105 transition-transform duration-200">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 flex items-center">
              <svg
                className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-green-500 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="flex-1">Timer</span>
            </h2>
            <div className="overflow-x-auto">
              <Timer
                time={timerTime}
                isRunning={timerRunning}
                isPaused={timerPaused}
                onStart={handleStartTimer}
                onPause={handlePauseTimer}
                onStop={handleStopTimer}
                onTimeChange={handleTimerTimeChange}
              />
            </div>
          </div>

          {/* Alarm Section */}
          <div className="bg-white rounded-lg sm:rounded-2xl shadow p-4 sm:p-6 lg:p-8 transform hover:scale-105 transition-transform duration-200">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 flex items-center">
              <svg
                className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-green-500 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="flex-1">Alarme</span>
            </h2>
            <div className="w-full">
              <Alarm
                currentTime={currentTime}
                alarms={alarms}
                onAddAlarm={handleAddAlarm}
                onRemoveAlarm={handleRemoveAlarm}
                onToggleAlarm={handleToggleAlarm}
              />
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4 lg:gap-6 mb-8 sm:mb-12">
          <div className="bg-white rounded-lg shadow p-3 sm:p-4 lg:p-6 text-center">
            <div className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-2 sm:mb-3 lg:mb-4">
              <svg
                className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>
            <h3 className="text-sm sm:text-base lg:text-lg font-semibold text-gray-900 mb-1 sm:mb-2">
              Rápido
            </h3>
            <p className="text-xs sm:text-sm lg:text-base text-gray-600">
              Carregamento instantâneo
            </p>
          </div>
          <div className="bg-white rounded-lg shadow p-3 sm:p-4 lg:p-6 text-center">
            <div className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-2 sm:mb-3 lg:mb-4">
              <svg
                className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                />
              </svg>
            </div>
            <h3 className="text-sm sm:text-base lg:text-lg font-semibold text-gray-900 mb-1 sm:mb-2">
              Seguro
            </h3>
            <p className="text-xs sm:text-sm lg:text-base text-gray-600">
              Dados protegidos localmente
            </p>
          </div>
          <div className="bg-white rounded-lg shadow p-3 sm:p-4 lg:p-6 text-center">
            <div className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-2 sm:mb-3 lg:mb-4">
              <svg
                className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
                />
              </svg>
            </div>
            <h3 className="text-sm sm:text-base lg:text-lg font-semibold text-gray-900 mb-1 sm:mb-2">
              Offline
            </h3>
            <p className="text-xs sm:text-sm lg:text-base text-gray-600">
              Funciona mesmo sem conexão com internet
            </p>
          </div>
          <div className="bg-white rounded-lg shadow p-3 sm:p-4 lg:p-6 text-center">
            <div className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-2 sm:mb-3 lg:mb-4">
              <svg
                className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </div>
            <h3 className="text-sm sm:text-base lg:text-lg font-semibold text-gray-900 mb-1 sm:mb-2">
              Notificações
            </h3>
            <p className="text-xs sm:text-sm lg:text-base text-gray-600">
              Alertas sonoros para alarmes e timers
            </p>
          </div>
        </div>

        {/* How to Use Section */}
        <section className="bg-white rounded-lg sm:rounded-2xl shadow p-4 sm:p-6 lg:p-8 mb-6 sm:mb-8 lg:mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 sm:mb-6">
            Como Usar o Relógio Digital
          </h2>

          <div className="text-center mb-6 sm:mb-8">
            <p className="text-sm sm:text-base lg:text-lg text-gray-600 max-w-3xl mx-auto">
              Nosso relógio digital é uma ferramenta completa para gerenciamento do seu tempo. Seja
              para trabalho, estudos ou atividades físicas, temos todas as funcionalidades que você
              precisa em um único lugar.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:gap-6 lg:gap-8">
            <div>
              <div className="bg-green-50 rounded-lg p-4 sm:p-6 mb-4 sm:mb-6">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center">
                  <svg
                    className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-green-600 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="flex-1">Relógio Digital</span>
                </h3>
                <p className="text-sm sm:text-base text-gray-600 mb-3 sm:mb-4">
                  O relógio digital exibe a hora atual com precisão atômica, sincronizado
                  automaticamente com seu dispositivo. Perfeito para:
                </p>
                <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 mb-3 sm:mb-4 space-y-1 sm:space-y-2">
                  <li>Visualização em formato 24 horas para maior precisão</li>
                  <li>Data completa em português com dia da semana</li>
                  <li>Indicadores de status de sincronização</li>
                  <li>Funcionamento offline garantido</li>
                </ul>
                <div className="bg-white rounded p-3 sm:p-4 text-xs sm:text-sm text-gray-500">
                  <strong>Dica:</strong> O relógio mantém a precisão mesmo offline, mas recomendamos
                  sincronizar periodicamente para máxima exatidão.
                </div>
              </div>

              <div className="bg-blue-50 rounded-lg p-4 sm:p-6 mb-4 sm:mb-6">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center">
                  <svg
                    className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-blue-600 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="flex-1">Cronômetro Profissional</span>
                </h3>
                <p className="text-sm sm:text-base text-gray-600 mb-3 sm:mb-4">
                  Cronômetro de alta precisão com resolução de milissegundos. Ideal para:
                </p>
                <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 mb-3 sm:mb-4 space-y-1 sm:space-y-2">
                  <li>Treinos e atividades físicas</li>
                  <li>Medições precisas de tempo</li>
                  <li>Controle de produtividade</li>
                  <li>Competições e jogos</li>
                </ul>
                <div className="space-y-2">
                  <p className="text-sm sm:text-base text-gray-600">
                    <strong>Como usar:</strong>
                  </p>
                  <ol className="list-decimal list-inside text-sm sm:text-base text-gray-600 ml-2">
                    <li>Clique em &ldquo;Iniciar&rdquo; para começar a contagem</li>
                    <li>Use &ldquo;Parar&rdquo; para interromper temporariamente</li>
                    <li>&ldquo;Zerar&rdquo; reinicia a contagem do zero</li>
                    <li>&ldquo;Salvar&rdquo; armazena o tempo atual no histórico</li>
                  </ol>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-purple-50 rounded-lg p-4 sm:p-6 mb-4 sm:mb-6">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center">
                  <svg
                    className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-purple-600 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="flex-1">Timer Inteligente</span>
                </h3>
                <p className="text-sm sm:text-base text-gray-600 mb-3 sm:mb-4">
                  Timer versátil com contagem regressiva e alertas. Perfeito para:
                </p>
                <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 mb-3 sm:mb-4 space-y-1 sm:space-y-2">
                  <li>Método Pomodoro (25 min trabalho/5 min pausa)</li>
                  <li>Controle de tempo de cozimento</li>
                  <li>Intervalos em apresentações</li>
                  <li>Meditação e exercícios</li>
                </ul>
                <div className="bg-white rounded p-3 sm:p-4 text-xs sm:text-sm text-gray-500">
                  <strong>Exemplo Pomodoro:</strong>
                </div>
                <div className="space-y-2">
                  <ol className="list-decimal list-inside text-sm sm:text-base text-gray-600 ml-2">
                    <li>Configure 25 minutos para trabalho</li>
                    <li>Aguarde o alarme sonoro</li>
                    <li>Configure 5 minutos para pausa</li>
                    <li>Repita o ciclo 4 vezes</li>
                  </ol>
                </div>
              </div>

              <div className="bg-yellow-50 rounded-lg p-4 sm:p-6">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center">
                  <svg
                    className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-yellow-600 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  <span className="flex-1">Sistema de Alarmes</span>
                </h3>
                <p className="text-sm sm:text-base text-gray-600 mb-3 sm:mb-4">
                  Alarmes personalizáveis com repetição. Útil para:
                </p>
                <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 mb-3 sm:mb-4 space-y-1 sm:space-y-2">
                  <li>Horários de medicamentos</li>
                  <li>Rotina de trabalho/estudo</li>
                  <li>Compromissos importantes</li>
                  <li>Lembretes diários</li>
                </ul>
                <div className="space-y-2">
                  <p className="text-sm sm:text-base text-gray-600">
                    <strong>Configuração rápida:</strong>
                  </p>
                  <ol className="list-decimal list-inside text-sm sm:text-base text-gray-600 ml-2">
                    <li>Clique em &ldquo;Adicionar Alarme&rdquo;</li>
                    <li>Escolha o horário desejado</li>
                    <li>Selecione os dias de repetição</li>
                    <li>Adicione uma descrição (opcional)</li>
                    <li>Ative/desative conforme necessário</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8 border-t border-gray-200 pt-8">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">Recursos Avançados</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-green-50 rounded-lg p-6">
                <h4 className="font-semibold text-green-800 mb-2">Persistência Inteligente</h4>
                <p className="text-green-600">
                  Seus alarmes, configurações e preferências são salvos automaticamente no
                  navegador. Não perca mais suas configurações ao fechar a página.
                </p>
              </div>
              <div className="bg-green-50 rounded-lg p-6">
                <h4 className="font-semibold text-green-800 mb-2">Modo Offline</h4>
                <p className="text-green-600">
                  Trabalhe sem preocupações mesmo sem internet. Todas as funcionalidades continuam
                  disponíveis offline com sincronização automática quando reconectado.
                </p>
              </div>
              <div className="bg-green-50 rounded-lg p-6">
                <h4 className="font-semibold text-green-800 mb-2">Sistema de Notificações</h4>
                <p className="text-green-600">
                  Alertas sonoros e visuais para timers e alarmes, mesmo com a aba em segundo plano
                  ou o navegador minimizado.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 text-center">
            <a
              href="/blog"
              className="inline-flex items-center text-green-600 hover:text-green-700 font-medium group"
            >
              <span>Descubra mais dicas e tutoriais em nosso blog</span>
              <svg
                className="w-5 h-5 ml-2 transform transition-transform group-hover:translate-x-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </a>
          </div>
        </section>

        {/* Support Section */}
        <section className="bg-gradient-to-r from-green-50 to-green-100 rounded-lg sm:rounded-2xl p-4 sm:p-6 lg:p-8 mb-6 sm:mb-8 lg:mb-12">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2 sm:mb-4">
              Precisa de Ajuda?
            </h2>
            <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
              Encontre respostas para suas dúvidas em nossa seção de perguntas frequentes ou entre
              em contato com nossa equipe de suporte.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4">
              <a
                href="/faq"
                className="inline-flex items-center justify-center px-4 sm:px-6 py-2 sm:py-3 bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-200 text-gray-700 text-sm sm:text-base"
              >
                <svg
                  className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                FAQ
              </a>
              <a
                href="/sobre"
                className="inline-flex items-center justify-center px-4 sm:px-6 py-2 sm:py-3 bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-200 text-gray-700 text-sm sm:text-base"
              >
                <svg
                  className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                Contato
              </a>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
