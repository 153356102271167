import React, { useEffect, startTransition } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';

function App() {
  useEffect(() => {
    startTransition(() => {
      // Initial router setup
    });
  }, []);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
