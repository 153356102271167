import { createBrowserRouter, defer } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Cookies from './pages/Cookies';
import About from './pages/About';
import FAQ from './pages/FAQ';
import GenerateImages from './pages/GenerateImages';

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/blog',
          element: <Blog />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/blog/:slug',
          element: <BlogPost />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/privacidade',
          element: <PrivacyPolicy />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/termos',
          element: <TermsOfUse />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/cookies',
          element: <Cookies />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/sobre',
          element: <About />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/faq',
          element: <FAQ />,
          loader: () => defer({ ready: true }),
        },
        {
          path: '/gerar-imagens',
          element: <GenerateImages />,
          loader: () => defer({ ready: true }),
        },
      ],
    },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_normalizeFormMethod: true,
      v7_prependBasename: true,
    },
    basename: process.env.PUBLIC_URL || '/',
  }
);

export default router;
