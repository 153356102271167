import React from 'react';
import { useParams, Link } from 'react-router-dom';

function BlogPost() {
  const { slug } = useParams();

  const posts = {
    'metodo-pomodoro': {
      title: 'Método Pomodoro: Maximize sua Produtividade',
      author: 'Maria Santos',
      date: '2024-03-15',
      readTime: '8 min',
      image: '/blog/metodo-pomodoro.jpg',
      description:
        'Aprenda como usar o Método Pomodoro para aumentar sua produtividade e melhorar sua gestão de tempo com nosso timer digital.',
      content: `
        <article class="text-gray-900 leading-relaxed">
          <section class="mb-12">
            <h1 class="text-4xl font-bold mb-8 text-gray-900">Método Pomodoro: O Guia Completo</h1>

            <h2 class="text-2xl font-bold mb-6 text-gray-800">O que é o Método Pomodoro?</h2>
            <p class="mb-6 text-lg leading-relaxed">
              O <strong>Método Pomodoro</strong> é uma técnica simples e eficaz de gerenciamento de tempo. Criada por Francesco Cirillo nos anos 1980, seu nome vem do timer de cozinha em formato de tomate (<em>pomodoro</em> em italiano) usado pelo criador.
            </p>
            <p class="mb-6 text-lg leading-relaxed">
              Esta técnica se baseia em um princípio simples: <strong>alternar períodos focados de trabalho com pausas estratégicas</strong>.
            </p>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Como Funciona o Método Pomodoro?</h2>
            <p class="mb-6 text-lg leading-relaxed">
              O processo é dividido em 5 etapas simples:
            </p>
            <ol class="space-y-4 ml-6 list-decimal text-lg">
              <li class="pl-2"><strong>Escolha a tarefa:</strong> Defina o que precisa ser feito</li>
              <li class="pl-2"><strong>Configure 25 minutos:</strong> Este é um "pomodoro"</li>
              <li class="pl-2"><strong>Trabalhe com foco:</strong> Mantenha-se concentrado até o timer tocar</li>
              <li class="pl-2"><strong>Faça uma pausa curta:</strong> 5 minutos para descansar</li>
              <li class="pl-2"><strong>Pausa longa:</strong> Após 4 pomodoros, descanse 15-30 minutos</li>
            </ol>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Por Que o Método Pomodoro Funciona?</h2>
            <p class="mb-6 text-lg leading-relaxed">
              A eficácia do método está baseada em <strong>5 benefícios principais</strong>:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Foco aprimorado:</strong> Reduz a fadiga mental e aumenta a concentração</li>
              <li class="pl-2"><strong>Menos distrações:</strong> Elimina interrupções desnecessárias</li>
              <li class="pl-2"><strong>Maior motivação:</strong> Divide grandes tarefas em objetivos alcançáveis</li>
              <li class="pl-2"><strong>Melhor planejamento:</strong> Ajuda a estimar tempo de forma realista</li>
              <li class="pl-2"><strong>Equilíbrio trabalho-descanso:</strong> Previne o esgotamento</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Como Usar Nosso Timer Digital</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Nosso <strong>timer digital</strong> foi desenvolvido especialmente para o Método Pomodoro. Veja como começar:
            </p>
            <ol class="space-y-4 ml-6 list-decimal text-lg">
              <li class="pl-2"><strong>Acesse o Timer:</strong> Encontre a função na nossa plataforma</li>
              <li class="pl-2"><strong>Configure 25 minutos:</strong> Use nossos presets prontos</li>
              <li class="pl-2"><strong>Ative os alarmes:</strong> Notificações para pausas</li>
              <li class="pl-2"><strong>Acompanhe seu progresso:</strong> Registre suas sessões</li>
            </ol>
            <div class="mt-6 p-4 bg-green-50 rounded-lg">
              <p class="text-base text-green-800">
                <strong>Dica:</strong> Comece com sessões mais curtas se 25 minutos parecer muito. O importante é manter a consistência.
              </p>
            </div>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Dicas para Maximizar sua Produtividade</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Priorize tarefas:</strong> Liste o que precisa ser feito</li>
              <li class="pl-2"><strong>Elimine distrações:</strong> Silencie notificações durante os pomodoros</li>
              <li class="pl-2"><strong>Respeite as pausas:</strong> Elas são tão importantes quanto o trabalho</li>
              <li class="pl-2"><strong>Ajuste o tempo:</strong> Adapte a duração conforme sua necessidade</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Conclusão</h2>
            <p class="text-lg leading-relaxed mb-6">
              O <strong>Método Pomodoro</strong> é mais que uma técnica de gestão de tempo - é uma forma de desenvolver uma relação mais saudável com sua produtividade.
            </p>
            <p class="text-lg leading-relaxed">
              Com nosso timer digital, você tem todas as ferramentas necessárias para implementar esta técnica de forma eficiente e personalizada.
            </p>
          </section>
        </article>
      `,
    },
    'rotina-matinal': {
      title: 'Como Criar uma Rotina Matinal Produtiva',
      author: 'João Silva',
      date: '2024-03-12',
      readTime: '6 min',
      image: '/blog/rotina-matinal.jpg',
      description:
        'Descubra como estabelecer uma rotina matinal eficiente que aumentará sua produtividade e reduzirá o estresse diário.',
      content: `
        <article class="text-gray-900 leading-relaxed">
          <section class="mb-12">
            <h1 class="text-4xl font-bold mb-8 text-gray-900">Rotina Matinal: O Guia para Começar Bem o Dia</h1>

            <h2 class="text-2xl font-bold mb-6 text-gray-800">Por que uma Rotina Matinal é Importante?</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Uma <strong>rotina matinal bem estruturada</strong> é fundamental para definir o tom do seu dia. Pesquisas mostram que pessoas com rotinas matinais consistentes são até <strong>20% mais produtivas</strong> e experimentam níveis significativamente menores de estresse.
            </p>
            <p class="mb-6 text-lg leading-relaxed">
              O segredo está em criar uma sequência de atividades que prepare seu corpo e mente para os desafios do dia.
            </p>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Elementos Essenciais de uma Rotina Matinal</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Uma rotina matinal eficiente deve incluir:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Despertar consistente:</strong> Acorde no mesmo horário todos os dias</li>
              <li class="pl-2"><strong>Hidratação imediata:</strong> Beba água ao acordar para ativar o metabolismo</li>
              <li class="pl-2"><strong>Movimento suave:</strong> 10-15 minutos de exercícios leves</li>
              <li class="pl-2"><strong>Planejamento diário:</strong> Revise suas metas e prioridades</li>
              <li class="pl-2"><strong>Nutrição adequada:</strong> Café da manhã equilibrado e nutritivo</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Configurando Alarmes Estratégicos</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Use nosso <strong>sistema de alarmes inteligente</strong> para criar uma sequência eficaz:
            </p>
            <ol class="space-y-4 ml-6 list-decimal text-lg">
              <li class="pl-2"><strong>Alarme principal:</strong> Horário ideal para despertar</li>
              <li class="pl-2"><strong>Alarme de segurança:</strong> 5-10 minutos após o principal</li>
              <li class="pl-2"><strong>Lembretes estratégicos:</strong> Para momentos-chave da rotina</li>
            </ol>
            <div class="mt-6 p-4 bg-green-50 rounded-lg">
              <p class="text-base text-green-800">
                <strong>Dica:</strong> Configure sons diferentes para cada tipo de alarme, ajudando seu cérebro a identificar o propósito de cada um.
              </p>
            </div>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Mantendo a Consistência</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Preparação noturna:</strong> Organize tudo na noite anterior</li>
              <li class="pl-2"><strong>Zona livre de celular:</strong> Evite telas nos primeiros 30 minutos</li>
              <li class="pl-2"><strong>Rotina constante:</strong> Mantenha o padrão mesmo nos fins de semana</li>
              <li class="pl-2"><strong>Ajustes graduais:</strong> Mude o horário de despertar aos poucos</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Monitoramento e Ajustes</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Nosso aplicativo oferece ferramentas para:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Registro de horários:</strong> Acompanhe seus padrões de despertar</li>
              <li class="pl-2"><strong>Análise de consistência:</strong> Visualize seu progresso</li>
              <li class="pl-2"><strong>Ajustes inteligentes:</strong> Otimize seus alarmes automaticamente</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Conclusão</h2>
            <p class="text-lg leading-relaxed mb-6">
              Criar uma <strong>rotina matinal eficiente</strong> é um processo gradual que requer paciência e consistência. Com as ferramentas certas e comprometimento, você pode transformar suas manhãs e, consequentemente, seus dias.
            </p>
            <p class="text-lg leading-relaxed">
              Use nosso aplicativo para começar sua jornada rumo a manhãs mais produtivas e dias mais equilibrados.
            </p>
          </section>
        </article>
      `,
    },
    'tecnicas-cronometragem': {
      title: '7 Técnicas Avançadas de Cronometragem',
      author: 'Pedro Oliveira',
      date: '2024-03-10',
      readTime: '10 min',
      image: '/blog/tecnicas-cronometragem.jpg',
      description:
        'Domine técnicas avançadas de cronometragem para otimizar seu tempo e aumentar sua produtividade em qualquer atividade.',
      content: `
        <article class="text-gray-900 leading-relaxed">
          <section class="mb-12">
            <h1 class="text-4xl font-bold mb-8 text-gray-900">Técnicas Avançadas de Cronometragem: Guia Completo</h1>

            <p class="mb-6 text-lg leading-relaxed">
              A <strong>cronometragem eficiente</strong> é uma habilidade fundamental para maximizar sua produtividade. Conheça as técnicas mais eficazes e como aplicá-las no seu dia a dia.
            </p>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">1. Cronometragem Intervalada</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Ideal para treinos HIIT e estudos intensivos, esta técnica alterna períodos de:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Alta intensidade:</strong> 20-30 minutos de foco total</li>
              <li class="pl-2"><strong>Recuperação:</strong> 5-10 minutos de pausa ativa</li>
              <li class="pl-2"><strong>Repetições:</strong> 3-4 ciclos por sessão</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">2. Cronometragem em Blocos</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Divida grandes projetos em blocos gerenciáveis:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Planejamento:</strong> 15 minutos</li>
              <li class="pl-2"><strong>Execução:</strong> 45-60 minutos</li>
              <li class="pl-2"><strong>Revisão:</strong> 15 minutos</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">3. Técnica de Tempo Pareto</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Baseada no princípio 80/20, esta técnica ajuda a:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Identificar:</strong> Atividades de maior impacto</li>
              <li class="pl-2"><strong>Priorizar:</strong> 20% das tarefas que geram 80% dos resultados</li>
              <li class="pl-2"><strong>Otimizar:</strong> Tempo dedicado às atividades principais</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">4. Cronometragem Progressiva</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Aumente gradualmente seus períodos de foco:
            </p>
            <ol class="space-y-4 ml-6 list-decimal text-lg">
              <li class="pl-2"><strong>Semana 1:</strong> 25 minutos de foco</li>
              <li class="pl-2"><strong>Semana 2:</strong> 35 minutos de foco</li>
              <li class="pl-2"><strong>Semana 3:</strong> 45 minutos de foco</li>
              <li class="pl-2"><strong>Semana 4:</strong> 60 minutos de foco</li>
            </ol>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Implementação Prática</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Nosso cronômetro oferece recursos avançados para todas estas técnicas:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Intervalos flexíveis:</strong> Personalize seus tempos</li>
              <li class="pl-2"><strong>Múltiplos timers:</strong> Execute várias técnicas simultaneamente</li>
              <li class="pl-2"><strong>Análise detalhada:</strong> Acompanhe seu desempenho</li>
              <li class="pl-2"><strong>Exportação de dados:</strong> Monitore seu progresso</li>
            </ul>
            <div class="mt-6 p-4 bg-green-50 rounded-lg">
              <p class="text-base text-green-800">
                <strong>Dica:</strong> Comece com uma técnica e adicione outras gradualmente conforme sua adaptação.
              </p>
            </div>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Conclusão</h2>
            <p class="text-lg leading-relaxed mb-6">
              Dominar estas <strong>técnicas de cronometragem</strong> pode revolucionar sua produtividade. O segredo está em encontrar a combinação ideal para suas necessidades específicas.
            </p>
            <p class="text-lg leading-relaxed">
              Experimente diferentes técnicas usando nosso cronômetro e descubra qual funciona melhor para você.
            </p>
          </section>
        </article>
      `,
    },
    'gestao-tempo-home-office': {
      title: 'Gestão de Tempo em Home Office',
      author: 'Ana Luiza',
      date: '2024-03-08',
      readTime: '7 min',
      image: '/blog/gestao-tempo-home-office.jpg',
      description:
        'Aprenda estratégias eficazes para gerenciar seu tempo no trabalho remoto e manter o equilíbrio entre vida pessoal e profissional.',
      content: `
        <article class="text-gray-900 leading-relaxed">
          <section class="mb-12">
            <h1 class="text-4xl font-bold mb-8 text-gray-900">Home Office: Guia Definitivo de Gestão de Tempo</h1>

            <h2 class="text-2xl font-bold mb-6 text-gray-800">Desafios do Trabalho Remoto</h2>
            <p class="mb-6 text-lg leading-relaxed">
              O <strong>home office</strong> apresenta desafios únicos que exigem uma abordagem específica para gestão do tempo:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Distrações domésticas:</strong> TV, tarefas de casa, família</li>
              <li class="pl-2"><strong>Fronteiras indefinidas:</strong> Trabalho vs. vida pessoal</li>
              <li class="pl-2"><strong>Procrastinação:</strong> Maior tentação para adiar tarefas</li>
              <li class="pl-2"><strong>Comunicação remota:</strong> Desafios de coordenação com a equipe</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Estruturando seu Dia</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Use nosso <strong>timer digital</strong> para criar uma estrutura sólida:
            </p>
            <ol class="space-y-4 ml-6 list-decimal text-lg">
              <li class="pl-2"><strong>Horários fixos:</strong> Estabeleça início e fim do trabalho</li>
              <li class="pl-2"><strong>Pausas programadas:</strong> 5-15 minutos a cada 2 horas</li>
              <li class="pl-2"><strong>Blocos de foco:</strong> Períodos de 90 minutos para tarefas importantes</li>
              <li class="pl-2"><strong>Exercícios:</strong> 30 minutos de atividade física diária</li>
            </ol>
            <div class="mt-6 p-4 bg-green-50 rounded-lg">
              <p class="text-base text-green-800">
                <strong>Dica:</strong> Use nosso timer para criar alertas sonoros diferentes para cada tipo de atividade.
              </p>
            </div>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Ferramentas Essenciais</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Maximize sua produtividade com nossas ferramentas:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Timer focado:</strong> Para sessões de trabalho intenso</li>
              <li class="pl-2"><strong>Alarmes inteligentes:</strong> Lembretes de pausas e reuniões</li>
              <li class="pl-2"><strong>Cronômetro:</strong> Monitore tempo em cada tarefa</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Dicas de Produtividade</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Espaço dedicado:</strong> Monte um local específico para trabalho</li>
              <li class="pl-2"><strong>Rotina consistente:</strong> Mantenha horários regulares</li>
              <li class="pl-2"><strong>Técnicas de foco:</strong> Use métodos como Pomodoro</li>
              <li class="pl-2"><strong>Pausas ativas:</strong> Faça exercícios de alongamento</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Conclusão</h2>
            <p class="text-lg leading-relaxed mb-6">
              O sucesso no <strong>home office</strong> depende de uma gestão de tempo eficiente e do uso das ferramentas certas.
            </p>
            <p class="text-lg leading-relaxed">
              Com nosso sistema de timer e alarmes, você pode criar uma rotina produtiva e equilibrada, mesmo trabalhando de casa.
            </p>
          </section>
        </article>
      `,
    },
    'mindfulness-tempo': {
      title: 'Mindfulness e Gestão de Tempo',
      author: 'Carlos Eduardo',
      date: '2024-03-05',
      readTime: '9 min',
      image: '/blog/mindfulness-tempo.jpg',
      description:
        'Descubra como a prática de mindfulness pode melhorar sua gestão de tempo e aumentar sua produtividade de forma consciente.',
      content: `
        <article class="text-gray-900 leading-relaxed">
          <section class="mb-12">
            <h1 class="text-4xl font-bold mb-8 text-gray-900">Mindfulness: A Chave para uma Gestão de Tempo Consciente</h1>

            <h2 class="text-2xl font-bold mb-6 text-gray-800">O Poder do Mindfulness</h2>
            <p class="mb-6 text-lg leading-relaxed">
              O <strong>mindfulness</strong> pode transformar radicalmente sua relação com o tempo, permitindo que você:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Foque no presente:</strong> Reduza a ansiedade sobre o futuro</li>
              <li class="pl-2"><strong>Tome decisões conscientes:</strong> Escolha melhor suas prioridades</li>
              <li class="pl-2"><strong>Aumente a produtividade:</strong> Trabalhe com mais clareza mental</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Práticas Diárias Essenciais</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Meditação guiada:</strong> 10-20 minutos pela manhã</li>
              <li class="pl-2"><strong>Pausas conscientes:</strong> 2-3 minutos a cada hora</li>
              <li class="pl-2"><strong>Respiração focada:</strong> Durante momentos de estresse</li>
              <li class="pl-2"><strong>Atenção plena:</strong> Em todas as atividades</li>
            </ul>
            <div class="mt-6 p-4 bg-green-50 rounded-lg">
              <p class="text-base text-green-800">
                <strong>Dica:</strong> Comece com sessões curtas de 5 minutos e aumente gradualmente.
              </p>
            </div>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Usando Nosso Timer para Mindfulness</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Nosso aplicativo oferece recursos especiais para práticas mindful:
            </p>
            <ol class="space-y-4 ml-6 list-decimal text-lg">
              <li class="pl-2"><strong>Intervalos personalizados:</strong> Configure tempos ideais para meditação</li>
              <li class="pl-2"><strong>Sons suaves:</strong> Transições gentis entre atividades</li>
              <li class="pl-2"><strong>Lembretes conscientes:</strong> Alertas para pausas mindful</li>
              <li class="pl-2"><strong>Registro de práticas:</strong> Acompanhe seu progresso</li>
            </ol>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Exercícios Práticos</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Comece com estas técnicas simples:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Respiração 4-7-8:</strong> Inspire por 4s, segure por 7s, expire por 8s</li>
              <li class="pl-2"><strong>Escaneamento corporal:</strong> 5 minutos de atenção ao corpo</li>
              <li class="pl-2"><strong>Observação consciente:</strong> 3 minutos observando o ambiente</li>
              <li class="pl-2"><strong>Pausas mindful:</strong> 1 minuto de atenção plena</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Conclusão</h2>
            <p class="text-lg leading-relaxed mb-6">
              A prática de <strong>mindfulness</strong> não é apenas sobre meditação - é uma forma de viver mais consciente e produtiva.
            </p>
            <p class="text-lg leading-relaxed">
              Use nosso timer para incorporar estas práticas em sua rotina e descubra uma nova forma de relacionar-se com o tempo.
            </p>
          </section>
        </article>
      `,
    },
    'atualizacoes-relogio': {
      title: 'Novidades: Atualizações do Relógio Digital',
      author: 'Equipe Técnica',
      date: '2024-03-01',
      readTime: '5 min',
      image: '/blog/atualizacoes-relogio.jpg',
      description:
        'Conheça as últimas atualizações e novos recursos do nosso relógio digital, projetados para melhorar sua experiência.',
      content: `
        <article class="text-gray-900 leading-relaxed">
          <section class="mb-12">
            <h1 class="text-4xl font-bold mb-8 text-gray-900">Atualizações do Relógio Digital: Novos Recursos</h1>

            <p class="mb-6 text-lg leading-relaxed">
              Estamos constantemente aprimorando nossa plataforma para oferecer a melhor experiência possível. Confira as novidades:
            </p>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">1. Interface Aprimorada</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Design intuitivo:</strong> Nova disposição de elementos</li>
              <li class="pl-2"><strong>Modo escuro automático:</strong> Ajusta-se ao horário do dia</li>
              <li class="pl-2"><strong>Responsividade:</strong> Adaptação perfeita a qualquer tela</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">2. Timer Avançado</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Múltiplos timers:</strong> Execute vários cronômetros</li>
              <li class="pl-2"><strong>Sons personalizados:</strong> Escolha seus alertas</li>
              <li class="pl-2"><strong>Modos predefinidos:</strong> Configurações prontas para uso</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">3. Sistema de Alarmes</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Alarmes recorrentes:</strong> Programe repetições</li>
              <li class="pl-2"><strong>Integração calendário:</strong> Sincronize seus eventos</li>
              <li class="pl-2"><strong>Notificações smart:</strong> Alertas contextuais</li>
            </ul>
            <div class="mt-6 p-4 bg-green-50 rounded-lg">
              <p class="text-base text-green-800">
                <strong>Novidade:</strong> Agora você pode personalizar o som de cada tipo de alarme!
              </p>
            </div>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">4. Análise de Desempenho</h2>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Estatísticas detalhadas:</strong> Acompanhe seu uso</li>
              <li class="pl-2"><strong>Exportação de dados:</strong> Relatórios personalizados</li>
              <li class="pl-2"><strong>Insights:</strong> Sugestões de otimização</li>
            </ul>
          </section>

          <section class="mb-12 bg-gray-50 p-8 rounded-lg">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Próximas Atualizações</h2>
            <p class="mb-6 text-lg leading-relaxed">
              Em breve, você terá acesso a:
            </p>
            <ul class="space-y-4 ml-6 list-disc text-lg">
              <li class="pl-2"><strong>Sincronização:</strong> Integração entre dispositivos</li>
              <li class="pl-2"><strong>Backup na nuvem:</strong> Suas configurações sempre seguras</li>
              <li class="pl-2"><strong>Mais integrações:</strong> Compatibilidade expandida</li>
            </ul>
          </section>

          <section class="mb-12">
            <h2 class="text-2xl font-bold mb-6 text-gray-800">Conclusão</h2>
            <p class="text-lg leading-relaxed">
              Estas atualizações foram desenvolvidas com base no seu feedback. Continuaremos trabalhando para tornar nosso relógio digital ainda melhor.
            </p>
          </section>
        </article>
      `,
    },
  };

  const post = posts[slug];

  if (!post) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-2xl mx-auto px-4 py-8">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Post não encontrado</h1>
            <p className="text-lg text-gray-600 mb-6">
              O artigo que você está procurando não existe.
            </p>
            <Link to="/blog" className="text-green-600 hover:text-green-700 font-medium">
              Voltar para o Blog →
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <main className="max-w-3xl mx-auto px-4 py-12">
        {/* Breadcrumb */}
        <nav className="text-sm mb-8" aria-label="Navegação">
          <ol className="flex items-center space-x-2">
            <li>
              <Link to="/" className="text-gray-500 hover:text-gray-700">
                Início
              </Link>
            </li>
            <li className="text-gray-500">/</li>
            <li>
              <Link to="/blog" className="text-gray-500 hover:text-gray-700">
                Blog
              </Link>
            </li>
            <li className="text-gray-500">/</li>
            <li className="text-gray-900 font-medium">{post.title}</li>
          </ol>
        </nav>

        {/* Imagem de Capa */}
        <div className="relative mb-8">
          <img
            src={post.image}
            alt={post.title}
            className="w-full h-[400px] object-cover rounded-xl shadow-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-xl"></div>
          <div className="absolute bottom-0 left-0 right-0 p-8">
            <h1 className="text-4xl font-bold text-white mb-4 leading-tight">{post.title}</h1>
            <p className="text-xl text-gray-100 mb-4 leading-relaxed">{post.description}</p>
            <div className="flex items-center text-base text-gray-200">
              <span className="mr-4 font-medium">{post.author}</span>
              <time dateTime={post.date} className="mr-4">
                {new Date(post.date).toLocaleDateString('pt-BR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </time>
              <span>{post.readTime} de leitura</span>
            </div>
          </div>
        </div>

        {/* Conteúdo do Artigo */}
        <div
          className="article-content prose prose-lg max-w-none"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />

        {/* Rodapé do Artigo */}
        <footer className="mt-16 pt-8 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <Link
              to="/blog"
              className="text-lg inline-flex items-center text-green-600 hover:text-green-700 font-medium"
            >
              ← Voltar para o Blog
            </Link>
            <div className="flex items-center space-x-6">
              <button
                className="text-lg text-gray-600 hover:text-green-600 font-medium"
                aria-label="Compartilhar artigo"
              >
                Compartilhar
              </button>
              <button
                className="text-lg text-gray-600 hover:text-green-600 font-medium"
                aria-label="Salvar nos favoritos"
              >
                Salvar
              </button>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default BlogPost;
