import React from 'react';

function Alarm({ alarms, onAddAlarm, onRemoveAlarm, onToggleAlarm }) {
  const formatTime = date => {
    return date.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const formatDays = days => {
    const dayNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    return days.map(day => dayNames[day]).join(', ');
  };

  const handleAddAlarm = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const time = formData.get('time');
    const days = Array.from(formData.getAll('days')).map(Number);
    const label = formData.get('label') || 'Alarme';

    const [hours, minutes] = time.split(':');
    const alarmTime = new Date();
    alarmTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

    onAddAlarm({
      id: Date.now(),
      time: alarmTime,
      days,
      label,
      enabled: true,
    });

    e.target.reset();
  };

  return (
    <div className="w-full">
      <form onSubmit={handleAddAlarm} className="mb-6">
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <input
              type="time"
              name="time"
              required
              className="w-full px-3 py-2 text-sm border-2 border-gray-300 rounded-lg focus:border-green-500 focus:outline-none"
            />
            <input
              type="text"
              name="label"
              placeholder="Descrição (opcional)"
              className="w-full px-3 py-2 text-sm border-2 border-gray-300 rounded-lg focus:border-green-500 focus:outline-none"
            />
          </div>

          <div className="grid grid-cols-4 sm:grid-cols-7 gap-2">
            {[0, 1, 2, 3, 4, 5, 6].map(day => (
              <label key={day} className="flex items-center space-x-1">
                <input
                  type="checkbox"
                  name="days"
                  value={day}
                  className="form-checkbox h-4 w-4 text-green-500 rounded border-gray-300 focus:ring-green-500"
                />
                <span className="text-xs sm:text-sm text-gray-700">
                  {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'][day]}
                </span>
              </label>
            ))}
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 text-sm bg-green-500 text-white rounded-lg font-medium hover:bg-green-600 transition-colors"
          >
            Adicionar Alarme
          </button>
        </div>
      </form>

      <div className="space-y-3">
        {alarms.map(alarm => (
          <div
            key={alarm.id}
            className="flex items-center justify-between bg-white p-3 rounded-lg shadow-sm border border-gray-200"
          >
            <div className="flex items-center space-x-3">
              <button
                onClick={() => onToggleAlarm(alarm.id)}
                className={`w-5 h-5 rounded-full border-2 flex items-center justify-center transition-colors ${
                  alarm.enabled ? 'bg-green-500 border-green-500' : 'bg-gray-200 border-gray-300'
                }`}
              >
                {alarm.enabled && (
                  <svg
                    className="w-3 h-3 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
              </button>
              <div>
                <div className="text-base sm:text-lg font-medium text-gray-800">
                  {formatTime(alarm.time)}
                </div>
                <div className="text-xs sm:text-sm text-gray-600">
                  {alarm.label} • {formatDays(alarm.days)}
                </div>
              </div>
            </div>
            <button
              onClick={() => onRemoveAlarm(alarm.id)}
              className="text-red-500 hover:text-red-600 p-1"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        ))}

        {alarms.length === 0 && (
          <div className="text-center text-gray-500 py-6">Nenhum alarme configurado</div>
        )}
      </div>
    </div>
  );
}

export default Alarm;
