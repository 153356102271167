import React, { useState } from 'react';

const FAQ = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const faqs = [
    {
      id: 1,
      question: 'Como usar o Relógio Digital?',
      answer:
        'O Relógio Digital é muito simples de usar. Na página inicial, você encontrará o horário atual automaticamente. Para outras funcionalidades como cronômetro, timer e alarme, basta clicar nos respectivos botões no menu principal.',
    },
    {
      id: 2,
      question: 'O Relógio Digital funciona offline?',
      answer:
        'Sim! Uma vez que você carregue o site, o Relógio Digital continuará funcionando mesmo sem conexão com a internet. No entanto, para sincronizar o horário com precisão, recomendamos manter a conexão ativa.',
    },
    {
      id: 3,
      question: 'Como configurar um alarme?',
      answer:
        'Para configurar um alarme, acesse a função Alarme, clique em "Adicionar Alarme", selecione o horário desejado e configure as opções de repetição. Você pode definir múltiplos alarmes e personalizá-los individualmente.',
    },
    {
      id: 4,
      question: 'O cronômetro tem limite de tempo?',
      answer:
        'Não, nosso cronômetro não tem limite de tempo. Você pode utilizá-lo por quanto tempo precisar, com precisão de milissegundos e capacidade de marcar voltas.',
    },
    {
      id: 5,
      question: 'Como ajustar o fuso horário?',
      answer:
        'O Relógio Digital detecta automaticamente seu fuso horário. Caso queira alterá-lo, vá até as configurações (ícone de engrenagem) e selecione o fuso horário desejado na lista disponível.',
    },
    {
      id: 6,
      question: 'O timer emite som ao finalizar?',
      answer:
        'Sim! O timer emite um alerta sonoro ao finalizar a contagem. Você pode escolher entre diferentes sons de alerta ou desativar o som nas configurações.',
    },
    {
      id: 7,
      question: 'Posso usar o Relógio Digital em dispositivos móveis?',
      answer:
        'Sim! Nossa aplicação é totalmente responsiva e funciona perfeitamente em smartphones e tablets. Você pode acessar todas as funcionalidades através do seu navegador móvel.',
    },
    {
      id: 8,
      question: 'Como salvar minhas configurações preferidas?',
      answer:
        'Suas configurações são salvas automaticamente no navegador. Se desejar sincronizar entre dispositivos, você pode criar uma conta gratuita e fazer login para manter suas preferências em qualquer lugar.',
    },
  ];

  const toggleQuestion = id => {
    setOpenQuestion(openQuestion === id ? null : id);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Perguntas Frequentes</h1>

      <div className="space-y-4">
        {faqs.map(faq => (
          <div key={faq.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <button
              className="w-full px-6 py-4 text-left flex items-center justify-between focus:outline-none"
              onClick={() => toggleQuestion(faq.id)}
            >
              <span className="font-medium text-gray-900">{faq.question}</span>
              <svg
                className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${
                  openQuestion === faq.id ? 'rotate-180' : ''
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {openQuestion === faq.id && (
              <div className="px-6 pb-4">
                <div className="border-t border-gray-200 pt-4">
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-12 bg-green-50 rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Ainda tem dúvidas?</h2>
        <p className="text-gray-600 mb-4">
          Se você não encontrou a resposta que procura, entre em contato com nossa equipe de
          suporte. Estamos aqui para ajudar!
        </p>
        <div className="flex items-center space-x-4">
          <a
            href="mailto:suporte@relogiodigital.com"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Enviar E-mail
          </a>
         
        </div>
      </div>
    </div>
  );
};

export default FAQ;
