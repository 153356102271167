import React from 'react';

function Stopwatch({
  cronometro,
  isRunning,
  onStart,
  onStop,
  onReset,
  onSave,
  savedTimes,
  onLoadTime,
  onClearSavedTimes,
}) {
  const formatTime = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time % 1) * 100);

    return (
      <div className="font-mono text-5xl font-bold text-green-600 tracking-wider">
        {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
        {String(seconds).padStart(2, '0')}:{String(milliseconds).padStart(2, '0')}
      </div>
    );
  };

  const formatSavedTime = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time % 1) * 100);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:${String(milliseconds).padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col items-center">
      <div className="mb-6">{formatTime(cronometro)}</div>

      <div className="flex gap-4 mb-6">
        <button
          onClick={isRunning ? onStop : onStart}
          className={`px-6 py-2 rounded-lg font-semibold transition-colors ${
            isRunning
              ? 'bg-red-500 hover:bg-red-600 text-white'
              : 'bg-green-500 hover:bg-green-600 text-white'
          }`}
        >
          {isRunning ? 'Parar' : 'Iniciar'}
        </button>
        <button
          onClick={onReset}
          className="px-6 py-2 rounded-lg font-semibold bg-gray-500 hover:bg-gray-600 text-white transition-colors"
        >
          Zerar
        </button>
        <button
          onClick={onSave}
          className="px-6 py-2 rounded-lg font-semibold bg-blue-500 hover:bg-blue-600 text-white transition-colors"
        >
          Salvar
        </button>
      </div>

      {savedTimes.length > 0 && (
        <div className="w-full">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-800">Tempos Salvos</h3>
            <button onClick={onClearSavedTimes} className="text-red-500 hover:text-red-600 text-sm">
              Limpar
            </button>
          </div>
          <div className="space-y-2">
            {savedTimes.map((time, index) => (
              <div
                key={index}
                className="flex justify-between items-center bg-gray-50 p-3 rounded-lg"
              >
                <span className="font-mono text-gray-700">{formatSavedTime(time)}</span>
                <button
                  onClick={() => onLoadTime(time)}
                  className="text-blue-500 hover:text-blue-600"
                >
                  Carregar
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Stopwatch;
