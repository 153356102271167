import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  const [selectedCategory, setSelectedCategory] = useState('todos');

  const categories = [
    { id: 'todos', name: 'Todos' },
    { id: 'produtividade', name: 'Produtividade' },
    { id: 'gestao-tempo', name: 'Gestão de Tempo' },
    { id: 'dicas', name: 'Dicas & Truques' },
    { id: 'tecnologia', name: 'Tecnologia' },
  ];

  const articles = [
    {
      id: 1,
      title: 'Método Pomodoro: Maximize sua Produtividade',
      excerpt:
        'Descubra como a técnica Pomodoro pode revolucionar sua forma de trabalhar e estudar, usando nosso timer para implementá-la de forma eficaz.',
      category: 'produtividade',
      author: 'Maria Santos',
      date: '2024-03-15',
      readTime: '8 min',
      image: '/blog/metodo-pomodoro.jpg',
      slug: 'metodo-pomodoro',
    },
    {
      id: 2,
      title: 'Como Criar uma Rotina Matinal Produtiva',
      excerpt:
        'Aprenda a configurar alarmes estratégicos e estabelecer uma rotina matinal que aumentará sua produtividade durante todo o dia.',
      category: 'gestao-tempo',
      author: 'João Silva',
      date: '2024-03-12',
      readTime: '6 min',
      image: '/blog/rotina-matinal.jpg',
      slug: 'rotina-matinal',
    },
    {
      id: 3,
      title: '7 Técnicas Avançadas de Cronometragem',
      excerpt:
        'Explore recursos avançados do cronômetro para melhorar seu desempenho em exercícios, estudos e tarefas profissionais.',
      category: 'dicas',
      author: 'Pedro Oliveira',
      date: '2024-03-10',
      readTime: '10 min',
      image: '/blog/tecnicas-cronometragem.jpg',
      slug: 'tecnicas-cronometragem',
    },
    {
      id: 4,
      title: 'Gestão de Tempo em Home Office',
      excerpt:
        'Dicas práticas para organizar seu tempo no trabalho remoto, usando timer e alarmes para manter o foco e a produtividade.',
      category: 'produtividade',
      author: 'Ana Luiza',
      date: '2024-03-08',
      readTime: '7 min',
      image: '/blog/gestao-tempo-home-office.jpg',
      slug: 'gestao-tempo-home-office',
    },
    {
      id: 5,
      title: 'Mindfulness e Gestão de Tempo',
      excerpt:
        'Como combinar técnicas de mindfulness com ferramentas de tempo para reduzir o estresse e aumentar o foco.',
      category: 'gestao-tempo',
      author: 'Carlos Eduardo',
      date: '2024-03-05',
      readTime: '9 min',
      image: '/blog/mindfulness-tempo.jpg',
      slug: 'mindfulness-tempo',
    },
    {
      id: 6,
      title: 'Novidades: Atualizações do Relógio Digital',
      excerpt:
        'Conheça as últimas atualizações da nossa plataforma, incluindo novos recursos de timer e sincronização.',
      category: 'tecnologia',
      author: 'Equipe Técnica',
      date: '2024-03-01',
      readTime: '5 min',
      image: '/blog/atualizacoes-relogio.jpg',
      slug: 'atualizacoes-relogio',
    },
  ];

  const filteredArticles =
    selectedCategory === 'todos'
      ? articles
      : articles.filter(article => article.category === selectedCategory);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Blog</h1>
          <p className="text-xl text-gray-600 mb-8">
            Artigos e dicas sobre gerenciamento de tempo, produtividade e bem-estar
          </p>

          {/* Categories */}
          <div className="flex flex-wrap justify-center gap-2 mb-8">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedCategory === category.id
                    ? 'bg-green-600 text-white'
                    : 'bg-white text-gray-600 hover:bg-green-50 hover:text-green-600'
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Featured Article */}
        {selectedCategory === 'todos' && (
          <div className="mb-12">
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
              <div className="md:flex">
                <div className="md:flex-shrink-0">
                  <img
                    className="h-48 w-full object-cover md:h-full md:w-48"
                    src={articles[0].image}
                    alt={articles[0].title}
                  />
                </div>
                <div className="p-8">
                  <div className="uppercase tracking-wide text-sm text-green-600 font-semibold">
                    Em Destaque
                  </div>
                  <h2 className="mt-2 text-2xl font-bold text-gray-900">{articles[0].title}</h2>
                  <p className="mt-3 text-gray-600">{articles[0].excerpt}</p>
                  <div className="mt-4 flex items-center">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={`/authors/${articles[0].author.toLowerCase().replace(' ', '-')}.png`}
                      alt={articles[0].author}
                    />
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">{articles[0].author}</p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={articles[0].date}>
                          {new Date(articles[0].date).toLocaleDateString('pt-BR')}
                        </time>
                        <span aria-hidden="true">&middot;</span>
                        <span>{articles[0].readTime} de leitura</span>
                      </div>
                    </div>
                  </div>
                  <Link
                    to={`/blog/${articles[0].slug}`}
                    className="mt-4 inline-flex items-center text-green-600 hover:text-green-700"
                  >
                    Ler artigo completo
                    <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Articles Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredArticles.slice(selectedCategory === 'todos' ? 1 : 0).map(article => (
            <article
              key={article.id}
              className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <img className="h-48 w-full object-cover" src={article.image} alt={article.title} />
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 mb-2">
                  <span className="capitalize">{article.category.replace('-', ' ')}</span>
                  <span className="mx-2">&bull;</span>
                  <span>{article.readTime}</span>
                </div>
                <h2 className="text-xl font-semibold text-gray-900 mb-2">{article.title}</h2>
                <p className="text-gray-600 mb-4">{article.excerpt}</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-8 w-8 rounded-full"
                      src={`/authors/${article.author.toLowerCase().replace(' ', '-')}.png`}
                      alt={article.author}
                    />
                    <span className="ml-2 text-sm text-gray-600">{article.author}</span>
                  </div>
                  <Link
                    to={`/blog/${article.slug}`}
                    className="text-green-600 hover:text-green-700 font-medium inline-flex items-center"
                  >
                    Ler mais
                    <svg className="ml-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
